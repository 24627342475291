/* eslint-disable no-async-promise-executor */
import * as Sentry from '@sentry/nextjs'
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import TokenService from './tokenService'
import { AuthMetadataResponse } from '@/hooks/api/auth/useAuthApi/useAuthApi.types'

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_SIGNATER_API}/v1`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

let isRefreshing = false
let failedQueue: Array<{
  resolve: (value?: unknown) => void
  reject: (reason?: any) => void
}> = []

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.headers) {
      // config.headers['Content-Type'] = 'application/json'

      const requireAuth = config.headers['Require-Auth']

      const token = TokenService.getLocalAccessToken()
      if (requireAuth && token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }
    return config
  },
  (error) => Promise.reject(error)
)

const transformHeaders = (
  headers: Record<string, string>
): Record<string, string> => {
  if (!headers) return headers
  return Object.keys(headers).reduce(
    (acc, key) => {
      acc[key] = headers[key]
      return acc
    },
    {} as Record<string, string>
  )
}

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${token}`
            }
            return api(originalRequest)
          })
          .catch((err) => Promise.reject(err))
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise(async (resolve, reject) => {
        try {
          const authMetadata = TokenService.getLocalAuthMetadata()
          if (!authMetadata) throw new Error('No auth metadata found')

          const { data } = await api.post<AuthMetadataResponse>(
            '/auth/refresh',
            {
              accessToken: authMetadata.accessToken,
              refreshToken: authMetadata.refreshToken
            }
          )

          const newAuthMetadata = {
            ...data,
            expiresInDate: new Date(
              new Date().getTime() + data.expiresIn * 1000
            )
          }

          TokenService.updateLocalAuthMetadata(newAuthMetadata)
          processQueue(null, newAuthMetadata.accessToken)

          if (originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${newAuthMetadata.accessToken}`
          }
          resolve(api(originalRequest))
        } catch (err) {
          processQueue(err, null)
          TokenService.removeAuthMetadata()
          reject(err)
        } finally {
          isRefreshing = false
        }
      })
    }

    const { response } = error
    if (response && response.status >= 400 && response.status < 500) {
      return Promise.reject(error)
    }

    const userContext = JSON.parse(Cookies.get('userContext') || '{}')
    const userInfo = userContext?.userAccountInformation

    const responseHeaders = transformHeaders(error.response.headers)

    Sentry.withScope((scope) => {
      scope.setContext('axios', {
        url: error.config.url,
        method: error.config.method,
        data: error.config.data,
        params: error.config.params,
        response: response
          ? {
              status: response.status,
              data: response.data,
              headers: transformHeaders(response.headers)
            }
          : null,
        ...responseHeaders
      })

      if (userInfo) {
        scope.setUser({
          id: userInfo.id,
          email: userInfo.email,
          username: userInfo.username
        })
      }

      scope.setFingerprint([
        error.config.method,
        error.config.url,
        response ? response.status.toString() : 'no_response'
      ])
      scope.setTransactionName(
        `API ${error.config.method.toUpperCase()} ${error.config.url}`
      )
      Sentry.captureException(error)
    })

    return Promise.reject(error)
  }
)

export default api
