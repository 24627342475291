import Cookies from 'js-cookie'
import { AuthMetadata } from '@/types/global'
import { TOKEN_KEY } from '@/constants/LocalStorageTokens'

const TokenService = {
  getLocalAccessToken() {
    const authMetadataJSON = Cookies.get(TOKEN_KEY)
    const authMetadata = JSON.parse(authMetadataJSON || '{}')

    const hasAuthMetadata =
      authMetadataJSON &&
      authMetadata?.accessToken &&
      authMetadata?.refreshToken

    return hasAuthMetadata ? authMetadata.accessToken : null
  },
  getLocalRefreshToken() {
    const authMetadataJSON = Cookies.get(TOKEN_KEY)
    const authMetadata = JSON.parse(authMetadataJSON || '{}')

    const hasAuthMetadata =
      authMetadataJSON &&
      authMetadata?.accessToken &&
      authMetadata?.refreshToken

    return hasAuthMetadata ? authMetadata.refreshToken : null
  },
  getLocalAuthMetadata() {
    const authMetadataJSON = Cookies.get(TOKEN_KEY)
    const authMetadata = JSON.parse(authMetadataJSON || '{}')

    const hasAuthMetadata =
      authMetadataJSON &&
      authMetadata?.accessToken &&
      authMetadata?.refreshToken

    return hasAuthMetadata ? authMetadata : null
  },
  updateLocalAuthMetadata(authMetadata: AuthMetadata) {
    Cookies.set(TOKEN_KEY, JSON.stringify(authMetadata), { expires: 365 })
  },
  removeAuthMetadata() {
    Cookies.remove(TOKEN_KEY)
  }
}

export default TokenService
