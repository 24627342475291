'use client'

import {
  createContext,
  useEffect,
  useContext,
  useState,
  useCallback
} from 'react'
import { useRouter } from 'next/router'
import { Lang } from '@/types/global'
import { useAuth } from '../Auth'
import { useProfileLanguageApi } from '@/hooks/api/administration/profile/language'

type Props = {
  children?: React.ReactNode
}

type LocaleContext = {
  loading: boolean
  lang: Lang
  isUpdatingLang: boolean
  setLang: (lang: Lang) => void
}

const store = createContext<LocaleContext>({} as LocaleContext)
const { Provider } = store

function Locale({ children }: Props) {
  const router = useRouter()
  const { userContext, refreshProfile, isLogged } = useAuth()
  const [lang, setLangInternal] = useState<Lang>(() =>
    isLogged ? userContext?.userAccountInformation?.language : 'PtBr'
  )
  const [loading, setLoading] = useState(false)
  const [isUpdatingLang, setIsUpdatingLang] = useState(false)
  const { useUpdateProfileLanguage } = useProfileLanguageApi()
  const { mutateAsync: mutateAsyncUpdateProfileLanguage } =
    useUpdateProfileLanguage()

  useEffect(() => {
    const queryLang = router.query?.lang as string
    const validLangs = {
      pt: 'PtBr',
      en: 'EnUs'
    } as Record<string, Lang>
    const targetLang = validLangs[queryLang]

    if (targetLang) {
      setLangInternal(targetLang)
    }
  }, [router, setLangInternal])

  useEffect(() => {
    if (
      !lang ||
      (userContext?.userAccountInformation?.language &&
        userContext?.userAccountInformation?.language !== lang)
    ) {
      setLangInternal(userContext?.userAccountInformation?.language)
    }
  }, [lang, setLangInternal, userContext?.userAccountInformation?.language])

  useEffect(() => {
    if (
      userContext?.userAccountInformation?.language !== lang &&
      !!userContext?.userAccountInformation?.language
    ) {
      setIsUpdatingLang(true)
      setLangInternal(userContext?.userAccountInformation?.language)
    } else {
      setIsUpdatingLang(false)
    }
  }, [lang, userContext?.userAccountInformation?.language, setLangInternal])

  const setLang = useCallback(
    async (lang: Lang) => {
      if (!isLogged) {
        setLangInternal(lang)
        return
      }
      try {
        setLoading(true)
        setIsUpdatingLang(true)
        setLangInternal(lang)
        await mutateAsyncUpdateProfileLanguage({
          language: lang
        })
        await refreshProfile()
      } catch (error) {
        console.error('error', error)
        setLangInternal(userContext?.userAccountInformation?.language)
      } finally {
        setLoading(false)
        setIsUpdatingLang(false)
      }
    },
    [
      isLogged,
      mutateAsyncUpdateProfileLanguage,
      refreshProfile,
      setLangInternal,
      userContext?.userAccountInformation?.language
    ]
  )

  return (
    <Provider value={{ loading, lang, isUpdatingLang, setLang }}>
      {children}
    </Provider>
  )
}

const useLocale = () => useContext(store)

export { Locale, useLocale }
