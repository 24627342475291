'use client'

import React from 'react'
import { useTheme } from 'next-themes'
import { Slide, ToastContainer } from 'react-toastify'
import { Auth } from './Auth'
import { Locale } from './Locale'
import { QueryParams } from './QueryParams'
import { TooltipProvider } from '@/ui/shadcn/mobile-tooltip'

// import { ThemeWhiteLabelProvider } from './ThemeWhiteLabel'

type Props = {
  children: React.ReactNode
}

export const Providers: React.FC<Props> = ({ children }) => {
  const { theme } = useTheme()

  return (
    <TooltipProvider>
      <QueryParams>
        <Auth>
          <Locale>
            {/* <ThemeWhiteLabelProvider></ThemeWhiteLabelProvider> */}
            {children}
          </Locale>
        </Auth>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          limit={2}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={theme === 'dark' ? 'dark' : 'light'}
          bodyClassName="text-gray-500 font-display font-normal text-sm"
          transition={Slide}
        />
      </QueryParams>
    </TooltipProvider>
  )
}
