// 'use client'

// import { createContext, useContext, useEffect, useState } from 'react'
// import {
//   PopoverContentProps,
//   PopoverProps,
//   PopoverTriggerProps
// } from '@radix-ui/react-popover'
// import {
//   TooltipContentProps,
//   TooltipProps,
//   TooltipTriggerProps
// } from '@radix-ui/react-tooltip'
// import { Popover, PopoverTrigger, PopoverContent } from './popover'
// import { Tooltip, TooltipTrigger, TooltipContent } from './tooltip'

// const TouchContext = createContext<boolean | undefined>(undefined)
// const useTouch = () => useContext(TouchContext)

// export const TouchProvider = (props: any) => {
//   const [isTouch, setTouch] = useState<boolean>()

//   useEffect(() => {
//     setTouch(window.matchMedia('(pointer: coarse)').matches)
//   }, [])

//   return <TouchContext.Provider value={isTouch} {...props} />
// }

// export const HybridTooltip = (props: TooltipProps & PopoverProps) => {
//   const isTouch = useTouch()

//   return isTouch ? <Popover {...props} /> : <Tooltip {...props} />
// }

// export const HybridTooltipTrigger = (
//   props: TooltipTriggerProps & PopoverTriggerProps
// ) => {
//   const isTouch = useTouch()

//   return isTouch ? <PopoverTrigger {...props} /> : <TooltipTrigger {...props} />
// }

// export const HybridTooltipContent = (
//   props: TooltipContentProps & PopoverContentProps
// ) => {
//   const isTouch = useTouch()

//   return isTouch ? <PopoverContent {...props} /> : <TooltipContent {...props} />
// }
'use client'

import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  PopoverContentProps,
  PopoverProps,
  PopoverTriggerProps
} from '@radix-ui/react-popover'
import {
  TooltipContentProps,
  TooltipProps,
  TooltipTriggerProps,
  TooltipProviderProps
} from '@radix-ui/react-tooltip'
import { Popover, PopoverTrigger, PopoverContent } from './popover'
import {
  TooltipProvider as OriginalTooltipProvider,
  Tooltip as OriginalTooltip,
  TooltipTrigger as OriginalTooltipTrigger,
  TooltipContent as OriginalTooltipContent
} from './tooltip'

const TouchContext = createContext<boolean | undefined>(undefined)
const useTouch = () => useContext(TouchContext)

export const TooltipProvider = ({
  children,
  ...props
}: TooltipProviderProps) => {
  const [isTouch, setTouch] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    setTouch(window.matchMedia('(pointer: coarse)').matches)
  }, [])

  return (
    <TouchContext.Provider value={isTouch}>
      <OriginalTooltipProvider {...props}>{children}</OriginalTooltipProvider>
    </TouchContext.Provider>
  )
}

export const Tooltip = (props: TooltipProps & PopoverProps) => {
  const isTouch = useTouch()

  return isTouch ? <Popover {...props} /> : <OriginalTooltip {...props} />
}

export const TooltipTrigger = (
  props: TooltipTriggerProps & PopoverTriggerProps
) => {
  const isTouch = useTouch()

  return isTouch ? (
    <PopoverTrigger {...props} />
  ) : (
    <OriginalTooltipTrigger {...props} />
  )
}

export const TooltipContent = (
  props: TooltipContentProps & PopoverContentProps
) => {
  const isTouch = useTouch()

  return isTouch ? (
    <PopoverContent {...props} />
  ) : (
    <OriginalTooltipContent {...props} />
  )
}
