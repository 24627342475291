'use client'

import { useState, useEffect } from 'react'

type SetValue<T> = React.Dispatch<React.SetStateAction<T>>

const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, SetValue<T>, boolean] => {
  const [storedValue, setStoredValue] = useState<T>(initialValue)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    try {
      const item = window.localStorage.getItem(key)

      if (
        item &&
        item !== 'undefined' &&
        item !== 'null' &&
        item !== 'NaN' &&
        item !== 'false' &&
        item !== undefined &&
        item !== null &&
        item !== '' &&
        !!item &&
        item !== `""` &&
        item !== '{}'
      ) {
        setStoredValue(JSON.parse(item))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [key])

  useEffect(() => {
    setIsLoading(true)
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue))
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [key, storedValue])

  return [storedValue, setStoredValue, isLoading]
}

export default useLocalStorage
