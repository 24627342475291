import dotenv from 'dotenv'

dotenv.config()

export const ENVIRONMENT =
  process.env.GITHUB_HEAD_REF === 'main' ? 'production' : 'development'
export const NEXT_PUBLIC_APP_URL =
  process.env.NEXT_PUBLIC_APP_URL || ENVIRONMENT === 'production'
    ? 'https://app.signater.com.br'
    : 'https://develop.signater-app.pages.dev'
export const RECAPTCHA_SITE_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''
export const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || ''
export const GLEAP_KEY = process.env.NEXT_PUBLIC_GLEAP_KEY || ''
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || ''
export const MIXPANEL_ENVIRONMENT =
  process.env.NEXT_PUBLIC_MIXPANEL_ENVIRONMENT || 'production'
