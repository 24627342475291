'use client'

import { useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '@/services/api'
import { useAuth } from '@/providers/Auth'
import { HttpMethod, QueryKeyElement } from './useApi.types'

const useApi = () => {
  const queryClient = useQueryClient()
  const {
    authMetadata,
    refreshTokenWithValidator,
    logoutAction,
    isSandbox,
    isLoadingAuthMetadata,
    userContext,
    refreshTokenPromiseRef
  } = useAuth()

  const headersApi = useMemo(
    () => ({
      'Content-Type': 'application/json',
      'X-Signater-SandboxMode': isSandbox,
      ...(userContext?.userAccountInformation?.id && {
        'X-Signater-UserAccountId': userContext?.userAccountInformation?.id
      }),
      'Require-Auth': !!authMetadata?.accessToken
    }),
    [
      isSandbox,
      userContext?.userAccountInformation?.id,
      authMetadata?.accessToken
    ]
  )

  const queryConfig = useMemo(
    () => ({
      retry: false,
      refetchOnWindowFocus: false,
      enabled:
        !!authMetadata?.accessToken &&
        !isLoadingAuthMetadata &&
        !refreshTokenPromiseRef.current,
      staleTime: Infinity
    }),
    [authMetadata?.accessToken, isLoadingAuthMetadata, refreshTokenPromiseRef]
  )

  const prepareData = (body: any): FormData | undefined => {
    if (body && body.file) {
      const formData = new FormData()
      formData.append('file', body.file)
      return formData
    }
    return undefined
  }

  const handleApi = async <Request = void | FormData, Response = void>(
    url: string,
    method: HttpMethod,
    body?: Request,
    options?: {
      requireAuth?: boolean
      accessToken?: string
      headers?: Record<string, string | boolean>
      responseType?: 'json' | 'blob' | 'text'
    }
  ): Promise<Response | null> => {
    const {
      requireAuth = true,
      accessToken: customAccessToken,
      headers: customHeaders = {},
      responseType = 'json'
    } = options || {}

    const accessToken = customAccessToken || authMetadata?.accessToken

    if (requireAuth && refreshTokenWithValidator) {
      try {
        await refreshTokenWithValidator()
      } catch (error) {
        logoutAction()

        throw new Error('Failed to refresh token.')
      }
    }

    const preparedData = body && (body as any).file ? prepareData(body) : body

    const response = await api({
      url,
      method,
      headers: {
        ...headersApi,
        ...customHeaders,
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
      },
      data: preparedData,
      responseType
    })

    return response.status === 204 ? null : response.data
  }

  const useBaseMutation = <Request = void, Response = void>(
    url: string,
    method: HttpMethod,
    queryKeys?: QueryKeyElement[],
    options?: {
      requireAuth?: boolean
      accessToken?: string
      headers?: Record<string, string | boolean>
      responseType?: 'json' | 'blob' | 'text'
    }
  ) =>
    useMutation({
      mutationFn: (data: Request) =>
        handleApi<Request, Response>(url, method, data, {
          ...options,
          headers: {
            ...headersApi,
            ...options?.headers
          }
        }),
      onSuccess: () => {
        if (queryKeys?.length) {
          queryKeys.forEach((queryKey: QueryKeyElement) => {
            queryClient.invalidateQueries({
              queryKey
            })
          })
        }
      }
    })

  const useDeleteMutation = (url: string, queryKeys?: QueryKeyElement[]) => {
    const queryClient = useQueryClient()

    return useMutation({
      mutationFn: (id: string) => handleApi(`${url}/${id}`, 'DELETE'),
      onSuccess: () => {
        if (queryKeys?.length) {
          queryKeys.forEach((queryKey: QueryKeyElement) => {
            queryClient.invalidateQueries({
              queryKey
            })
          })
        }
      }
    })
  }

  return {
    handleApi,
    queryConfig,
    useBaseMutation,
    useDeleteMutation
  }
}

export default useApi
